import { FaCarAlt, FaGlobeAmericas, FaTree } from "react-icons/fa";
import { MdForest } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";

export default function EnvironmentalImpact({ generatedToDate, powerSavingValue }) {
	// magic values gotten from Pablo via Locust Energy
	const TREES_CONSTANT = 0.0116;
	const CO2_CONSTANT = 1.54;
	const CARS_CONSTANT = 0.000155;
	return (
		<div className="w-full bg-white border border-black flex flex-col rounded-lg overflow-hidden">
			<p className="font-bold text-lg p-2 bg-black text-white text-center">Environmental Impact</p>
			<div className="flex h-full text-center h-full divide-black divide-x">

				<div className="relative w-full flex flex-col justify-around p-1 overflow-x-hidden">
					<div className="absolute top-0 bottom-0 md:-left-10 flex justify-center opacity-10 z-0 ">
						<MdForest className="h-full w-auto" color="green" />
					</div>
					<p className="text-xs md:text-sm font-bold z-[100]">Trees Planted</p>

					<p className="text-xs md:text-xl z-[100]">{Number((generatedToDate * TREES_CONSTANT).toFixed(1)).toLocaleString("en-US")}</p>
				</div>

				<div className="relative w-full flex flex-col justify-around p-1 overflow-x-hidden">
					<div className="absolute top-0 bottom-0 my-1 md:-left-10 flex justify-center opacity-10 z-0 ">
						<FaGlobeAmericas className="h-full w-auto" color="green" />
					</div>
					<p className="text-xs md:text-sm font-bold z-[100]">Lbs of CO2 Emissions</p>
					<p className="text-xs md:text-xl z-[100]">{Number((generatedToDate * CO2_CONSTANT).toFixed(1)).toLocaleString("en-US")}</p>
				</div>

				<div className="relative w-full flex flex-col justify-around p-1 overflow-x-hidden">
					<div className="absolute top-0 bottom-0 md:-left-10 flex justify-center opacity-10 z-0 ">
						<FaCarAlt className="h-full w-auto" color="green" />
					</div>
					<p className="text-xs md:text-sm font-bold z-[100]">Cars taken off the road</p>
					<p className="text-xs md:text-xl z-[100]">{Number((generatedToDate * CARS_CONSTANT).toFixed(1)).toLocaleString("en-US")}</p>
				</div>
			</div>
		</div>
	);
}
