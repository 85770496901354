import { useEffect, useState } from "react";
import GraphContainer2 from "./GraphContainer2";
import WeekSelectionDropdown from "./WeekSelectionDropdown";
import MyComposedGraph from "./MyComposedGraph";

export default function MyGraph({ dataList, timeFilter, containerTitle = "", startExpanded, solarEdgePercentage = 1 }) {
	const [selectedWeekSpan, setSelectedWeekSpan] = useState("");
	const [weekSpanOptions, setWeekSpanOptions] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [graphConfig, setGraphConfig] = useState([]);

	useEffect(() => {
		let newConfig = [];
		for (let i = 0; i < dataList.length; i++) {
			newConfig.push({
				label: dataList[i].label,
				...dataList[i].graphConfig,
				stackId: dataList[i].graphConfig.stackId ? dataList[i].graphConfig.stackId : dataList[i].label,
			});
		}
		setGraphConfig(newConfig);
	}, [dataList]);

	useEffect(() => {
		let filteredDataList = [];

		if (timeFilter === "trailing13") {
			const currentDate = new Date();
			const thirteenMonthsAgo = new Date(currentDate);
			thirteenMonthsAgo.setMonth(currentDate.getMonth() - 12);
			for (let i = 0; i < dataList.length; i++) {
				let filteredData = { ...dataList[i] };
				filteredData.data = filteredData.data.filter((item) => {
					const date = new Date(item[filteredData.timeKey]);
					return date >= thirteenMonthsAgo && date <= currentDate;
				});
				filteredDataList.push(filteredData);
			}
		} else if (timeFilter === "trailing30") {
			const endDate = new Date();
			const startDate = new Date();
			startDate.setDate(endDate.getDate() - 30);
			const dateArray = [];
			let currentDate = new Date(startDate);
			while (currentDate <= endDate) {
				dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
				currentDate.setDate(currentDate.getUTCDate() + 1);
			}
			for (let i = 0; i < dataList.length; i++) {
				let filteredData = { ...dataList[i] };
				filteredData.data = filteredData.data.filter((item) => {
					const dateKey = new Date(item[filteredData.timeKey]).toISOString().split("T")[0];
					return dateArray.includes(dateKey);
				});
				filteredDataList.push(filteredData);
			}
		} else if (timeFilter === "daily") {
			if (!selectedWeekSpan) return;
			const [start, end] = selectedWeekSpan.split(" - ").map((d) => {
				const parts = d.split("-").map((part) => parseInt(part, 10));
				const date = new Date();
				date.setFullYear(parts[0], parts[1] - 1, parts[2]);
				return date;
			});
			start.setHours(0, 0, 0, 0);
			end.setHours(23, 59, 59, 999);

			const dateArray = [];
			let currentDate = new Date(start);
			while (currentDate <= end) {
				dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
				currentDate.setDate(currentDate.getDate() + 1);
			}
			for (let i = 0; i < dataList.length; i++) {
				let filteredData = { ...dataList[i] };
				filteredData.data = filteredData.data.filter((item) => {
					const dateKey = new Date(item[filteredData.timeKey]).toISOString().split("T")[0];
					return dateArray.includes(dateKey);
				});
				filteredDataList.push(filteredData);
			}
		} else if (timeFilter === "weekly") {
			if (!selectedWeekSpan) return;
			const [start, end] = selectedWeekSpan.split(" - ").map((d) => {
				const parts = d.split("-").map((part) => parseInt(part, 10));
				const date = new Date();
				date.setFullYear(parts[0], parts[1] - 1, parts[2]);
				return date;
			});
			start.setHours(0, 0, 0, 0);
			end.setHours(23, 59, 59, 999);

			const dateArray = [];
			let currentDate = new Date(start);
			while (currentDate <= end) {
				dateArray.push(currentDate.toISOString().split("T")[0]);
				currentDate.setDate(currentDate.getDate() + 1);
			}

			for (let i = 0; i < dataList.length; i++) {
				let filteredData = { ...dataList[i] };
				const dataMap = {};
				filteredData.data.forEach((item) => {
					const dateKey = new Date(item[filteredData.timeKey]).toISOString().split("T")[0];
					dataMap[dateKey] = item;
				});

				const completeData = dateArray.map((date) => {
					return dataMap[date] || { [filteredData.timeKey]: date, [filteredData.dataKey]: 0 };
				});

				filteredData.data = completeData;
				filteredDataList.push(filteredData);
			}
		}
		filteredDataList.forEach((item) => {
			if (item.label === "SE Inverters") {
				item.data = item.data.map((mapData) => {
					return {
						...mapData,
						kWh: mapData.kWh * solarEdgePercentage,
					};
				});
			}
		});
		setFilteredData(filteredDataList);
	}, [dataList, selectedWeekSpan, solarEdgePercentage, timeFilter]);

	return (
		<GraphContainer2 title={containerTitle} expandedByDefault={startExpanded}>
			<div className="w-full">
				<WeekSelectionDropdown
					dataList={dataList}
					selectedWeekSpan={selectedWeekSpan}
					setSelectedWeekSpan={setSelectedWeekSpan}
					setWeekSpanOptions={setWeekSpanOptions}
					timeFilter={timeFilter}
					weekSpanOptions={weekSpanOptions}
				/>
				<MyComposedGraph dataList={filteredData} period={timeFilter} graphConfig={graphConfig} />
				<div className="flex gap-4 justify-center items-center mt-4">
					{graphConfig
						? graphConfig.map((mapData, index) => {
								if (mapData.parentLabel) {
									return null;
								}
								return (
									<div key={mapData.label}>
										<div className="flex gap-2 items-center">
											<label className="flex items-centers gap-1">
												<input
													type="checkbox"
													name={mapData.label}
													checked={mapData.visible}
													onChange={() => {
														let newConfigs = [...graphConfig];
														newConfigs[index].visible = !newConfigs[index].visible;
														setGraphConfig(newConfigs);
													}}
												/>
												{mapData.label}
											</label>
										</div>
										<div className="flex ">
											<div className="w-full">
												<button
													onClick={() => {
														let newConfigs = [...graphConfig];
														newConfigs[index].graphType = "line";
														setGraphConfig(newConfigs);
													}}
													className={`w-full ${
														mapData.graphType === "line"
															? "bg-blue-500 hover:bg-blue-600 text-white p-2"
															: "bg-slate-500 hover:bg-slate-600 text-white p-2"
													}`}>
													Line
												</button>
											</div>
											<div className="w-full">
												<button
													onClick={() => {
														let newConfigs = [...graphConfig];
														newConfigs[index].graphType = "bar";
														setGraphConfig(newConfigs);
													}}
													className={`w-full ${
														mapData.graphType === "bar"
															? "bg-blue-500 hover:bg-blue-600 text-white p-2"
															: "bg-slate-500 hover:bg-slate-600 text-white p-2"
													}`}>
													Bar
												</button>
											</div>
										</div>
									</div>
								);
						  })
						: null}
				</div>
			</div>
		</GraphContainer2>
	);
}
