import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import AlertList from "./AlertList";
import { useLocation } from "react-router-dom";
import { FaFileCsv } from "react-icons/fa";

function formatCSV(filteredAlerts) {
	let csvString = `Name,Ticket Id,Customer Reference #,Status,Address,Category,Last Modified Date\n`;

	filteredAlerts.forEach((alert) => {
		alert.site.tickets.forEach((ticket) => {
			const { name, id, customerReferenceNumber, status, address, category, lastModifiedDate } = ticket;
			csvString += `${name},${id},${customerReferenceNumber},${status},${address},${category},${lastModifiedDate}\n`;
		});
	})
	return csvString;
}

const downloadCSV = (csvString, filename = "data.csv") => {
	const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
	const link = document.createElement("a");
	link.href = URL.createObjectURL(blob);
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const Alerts = ({ sidebarOpen, api, token, isCommercial }) => {
	const { user } = useAuth0();
	const location = useLocation();
	const [fetchingData, setFetchingData] = useState(true);
	const [yellowAlerts, setYellowAlerts] = useState([]);
	const [redAlerts, setRedAlerts] = useState([]);
	const [selectedButton, setSelectedButton] = useState("all");
	const [selectedOptions, setSelectedOptions] = useState(["Plausible Disconnect", "Low Generation", "Generation Warning", "Incorrect Readings"]);
	const queryParams = new URLSearchParams(location.search);
	const [searchText, setSearchText] = useState(queryParams.get("query") || "");
	const [filteredAlerts, setFilteredAlerts] = useState([]);

	const handleCheckboxChange = (option) => {
		setSelectedOptions((prevSelected) => (prevSelected.includes(option) ? prevSelected.filter((item) => item !== option) : [...prevSelected, option]));
	};

	useEffect(() => {
		if (!user || !api || !token) {
			return;
		}
		setFetchingData(true);
		fetch(api + "/monitoring/all", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({ email: user.email, forAlerts: true }),
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
			})
			.then((jsonData) => {
				if (jsonData) {
					let newRedAlerts = [];
					let newYellowAlerts = [];
					jsonData.sites.forEach((site) => {
						if (site.alerts.length !== 0) {
							site.alerts.forEach((alert) => {
								if (alert === "Plausible Disconnect") {
									newRedAlerts.push({ type: "issue", label: "Plausible Disconnect", site: site });
								} else if (alert === "Low Generation") {
									newRedAlerts.push({ type: "issue", label: "Low Generation", site: site });
								} else if (alert === "Generation Warning") {
									newYellowAlerts.push({ type: "warning", label: "Generation Warning", site });
								} else if (alert === "Incorrect Readings") {
									newYellowAlerts.push({ type: "issue", label: "Incorrect Readings", site });
								}
							});
						}
					});
					setRedAlerts(newRedAlerts);
					setYellowAlerts(newYellowAlerts);
					setFetchingData(false);
				}
			});
	}, [api, token, user]);

	const exportData = () => {
		const csvString = formatCSV(filteredAlerts);
		downloadCSV(csvString, "alerts.csv");
	}

	return (
		<>
			<div
				className={`${
					user.email === "ps@1stle.com"
						? "bg-PSBackgroundImage"
						: user.email === "ess@1stle.com"
						? "bg-ESSBackgroundImage"
						: isCommercial
						? "bg-CommercialBackgroundImage"
						: "bg-1stleBackgroundImage"
				} fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover`}
			/>
			<div className={`${sidebarOpen ? "md:ml-64" : "md:ml-10"} z-[100] `}>
				<div className="text-sm text-start text-gray-800 m-10 overflow-hidden  ">
					<div className="flex items-center justify-center pb-10">
						{user.email === "ps@1stle.com" ? (
							<img className="h-32" src={process.env.REACT_APP_PUBLIC_STORAGE_LOGO_URL} alt="PUBLIC STORAGE" />
						) : user.email === "ess@1stle.com" ? (
							<img className="h-32" src={process.env.REACT_APP_EXTRA_SPACE_STORAGE_LOGO_URL} alt="EXTRA SPACE STORAGE" />
						) : (
							""
						)}
					</div>
					<div className="bg-slate-50 bg-opacity-95">
						{fetchingData ? (
							<div className="mt-4 p-4 bg-white flex justify-center items-center">
								<div className="  flex justify-center items-center flex-col p-5 ">
									<img src={process.env.REACT_APP_LOADING_SVG} alt="Loading..." />
									<p className="mt-2 font-bold">Loading Your Alerts...</p>
								</div>
							</div>
						) : (
							<>
								<div className="p-4">
									<div
										className="p-3 flex items-center gap-2 bg-white w-32 cursor-pointer border border-black rounded-lg select-none"
										onClick={exportData}>
										<FaFileCsv className="text-2xl cursor-pointer" />
										<p className="cursor-pointer">Export</p>
									</div>
								</div>
								<div className=" p-4 bg-opacity-95 rounded-lg">
									<h1 className="text-center font-bold text-3xl">Alerts</h1>
								</div>
								<div className="flex px-4 pb-2 justify-center w-full">
									<div className="w-full md:w-3/5 flex flex-col md:flex-row gap-4 justify-center border border-black bg-white text-black rounded-lg">
										<input
											type="text"
											className="flex-1 p-1  rounded-lg"
											placeholder="Search by site name..."
											value={searchText}
											onChange={(e) => setSearchText(e.target.value)}
										/>
									</div>
								</div>
								<div className="flex px-4 pb-2 justify-center w-full">
									<div className="w-full md:w-3/5 flex flex-col md:flex-row gap-4 justify-center">
										<button
											className={`flex-1 p-1 border border-black ${
												selectedButton === "all" ? "bg-blue-500 text-white" : "bg-white text-black"
											} rounded-lg`}
											onClick={() => setSelectedButton("all")}>
											All
										</button>
										<button
											className={`flex-1 p-1 border border-black ${
												selectedButton === "red" ? "bg-blue-500 text-white" : "bg-white text-black"
											} rounded-lg`}
											onClick={() => setSelectedButton("red")}>
											Issues
										</button>
										<button
											className={`flex-1 p-1 border border-black ${
												selectedButton === "yellow" ? "bg-blue-500 text-white" : "bg-white text-black"
											} rounded-lg`}
											onClick={() => setSelectedButton("yellow")}>
											Warnings
										</button>
									</div>
								</div>
								<div className="flex px-4 pb-2 justify-center w-full">
									<div className="w-full md:w-3/5 flex flex-col md:flex-row gap-4 justify-center border border-black bg-white text-black rounded-lg p-1">
										<div className="flex-1 p-1 ">
											<input
												type="checkbox"
												id="option1"
												checked={selectedOptions.includes("Plausible Disconnect")}
												onChange={() => handleCheckboxChange("Plausible Disconnect")}
											/>
											<label htmlFor="option1" className="ml-2">
												Plausible Disconnect
											</label>
										</div>
										<div className="flex-1 p-1 ">
											<input
												type="checkbox"
												id="option2"
												checked={selectedOptions.includes("Low Generation")}
												onChange={() => handleCheckboxChange("Low Generation")}
											/>
											<label htmlFor="option2" className="ml-2">
												Low Generation
											</label>
										</div>
										<div className="flex-1 p-1 ">
											<input
												type="checkbox"
												id="option3"
												checked={selectedOptions.includes("Incorrect Readings")}
												onChange={() => handleCheckboxChange("Incorrect Readings")}
											/>
											<label htmlFor="option3" className="ml-2">
												Incorrect Readings
											</label>
										</div>
										<div className="flex-1 p-1 ">
											<input
												type="checkbox"
												id="option4"
												checked={selectedOptions.includes("Generation Warning")}
												onChange={() => handleCheckboxChange("Generation Warning")}
											/>
											<label htmlFor="option4" className="ml-2">
												Generation Warning
											</label>
										</div>
									</div>
								</div>
								<AlertList
									selectedButton={selectedButton}
									yellowAlerts={yellowAlerts}
									redAlerts={redAlerts}
									selectedOptions={selectedOptions}
									searchText={searchText}
									filteredAlerts={filteredAlerts}
									setFilteredAlerts={setFilteredAlerts}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Alerts;
