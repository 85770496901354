import { useState } from "react";
import { MdClose } from "react-icons/md";
import { AiOutlineExpandAlt, AiOutlineShrink } from "react-icons/ai";
import { useAuth0 } from "@auth0/auth0-react";

export default function GraphContainer2({ removeGraph, title, children, expandedByDefault, allowResize = true }) {
	const [expand, setExpand] = useState(expandedByDefault ? true : false);
	return (
		<div className={`flex flex-col items-around justify-between bg-white border border-black rounded-lg overflow-hidden ${expand ? "col-span-2" : ""}`}>
			<div className="flex justify-between  p-2 bg-black text-white ">
				<div />
				<h2 className="text-lg font-bold">{title}</h2>
				<div className="flex gap-1">
					{!allowResize ? null : expand ? (
						<button onClick={() => setExpand(false)}>
							<AiOutlineShrink size={25} />
						</button>
					) : (
						<button onClick={() => setExpand(true)}>
							<AiOutlineExpandAlt size={25} />
						</button>
					)}
					{removeGraph ? (
						<button onClick={() => removeGraph()} className="hover:font">
							<MdClose size={25} />
						</button>
					) : null}
				</div>
			</div>

			<div className="p-2">{children}</div>
		</div>
	);
}
