import { useEffect } from "react";

export default function WeekSelectionDropdown({ timeFilter, dataList, selectedWeekSpan, setSelectedWeekSpan, weekSpanOptions, setWeekSpanOptions }) {
	function getWeekSpans(data, timeKey) {
		const weekSpans = new Set();

		data.forEach((item) => {
			const date = new Date(item[timeKey]);
			const startOfWeek = new Date(date.setUTCDate(date.getUTCDate() - date.getUTCDay()));
			const endOfWeek = new Date(startOfWeek);
			endOfWeek.setUTCDate(endOfWeek.getUTCDate() + 6);

			const span = `${startOfWeek.toISOString().split("T")[0]} - ${endOfWeek.toISOString().split("T")[0]}`;
			weekSpans.add(span);
		});

		return Array.from(weekSpans).sort();
	}

	function getCurrentWeekSpan() {
		const now = new Date();
		const startOfWeek = new Date(now.setUTCDate(now.getUTCDate() - now.getUTCDay()));
		const endOfWeek = new Date(startOfWeek);
		endOfWeek.setUTCDate(endOfWeek.getUTCDate() + 6);

		return `${startOfWeek.toISOString().split("T")[0]} - ${endOfWeek.toISOString().split("T")[0]}`;
	}

	const handleWeekSpanChange = (event) => {
		setSelectedWeekSpan(event.target.value);
	};

	useEffect(() => {
		if (!dataList || dataList.length === 0) {
			const currentSpan = getCurrentWeekSpan();
			setWeekSpanOptions([currentSpan]);
			if (selectedWeekSpan === "") {
				setSelectedWeekSpan(currentSpan);
			}
			return;
		}

		let allSpans = [];
		dataList.forEach((dataObject) => {
			const { data, timeKey, dataKey } = dataObject;
			if (data && timeKey && dataKey) {
				const filteredData = data.filter((item) => item[dataKey] && item[dataKey] !== 0);
				const spans = getWeekSpans(filteredData, timeKey);
				allSpans = allSpans.concat(spans);
			}
		});

		allSpans = Array.from(new Set(allSpans)).sort();

		if (allSpans.length === 0) {
			allSpans = [getCurrentWeekSpan()];
		}

		setWeekSpanOptions(allSpans);
		if (selectedWeekSpan === "") {
			setSelectedWeekSpan(allSpans[allSpans.length - 1]);
		}
	}, [dataList, selectedWeekSpan, setSelectedWeekSpan, setWeekSpanOptions]);

	if (timeFilter !== "weekly") {
		return null;
	}

	return (
		<div className="mb-4 flex gap-2 items-center">
			<label>Week: </label>
			<select value={selectedWeekSpan} onChange={handleWeekSpanChange} className="border border-black">
				<option value="">Select a week</option>
				{weekSpanOptions.map((span, index) => (
					<option key={index} value={span}>
						{span}
					</option>
				))}
			</select>
		</div>
	);
}
