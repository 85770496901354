import { useEffect, useState } from "react";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
const CustomTooltip = ({ active, payload, label, graphConfig }) => {
	if (active && payload && payload.length) {
		const stackGroups = payload.reduce((groups, item) => {
			const stackId = graphConfig.find((config) => config.label === item.name)?.stackId || "default";
			if (!groups[stackId]) {
				groups[stackId] = [];
			}
			groups[stackId].push(item);
			return groups;
		}, {});

		const stackDisplays = Object.entries(stackGroups)
			.filter(([_, items]) => items.length > 1)
			.map(([stackId, items]) => {
				const total = items.reduce((sum, item) => sum + (item.value || 0), 0);
				return <p key={`stack-${stackId}`}>{`Total for Stack ${stackId}: ${Math.ceil(total)} kWh`}</p>;
			});

		return (
			<div className="custom-tooltip" style={{ backgroundColor: "#fff", padding: "10px", border: "1px solid #ccc" }}>
				<p>
					<strong>{`${label}`}</strong>
				</p>
				{payload.map((entry, index) => (
					<p key={`item-${index}`} style={{ color: entry.color }}>
						{`${entry.name}: ${entry.name === "UW Expected" ? Math.floor(entry.value) : Math.ceil(entry.value)} kWh`}
					</p>
				))}
				{stackDisplays}
			</div>
		);
	}

	return null;
};

export default function MyComposedGraph({ dataList, graphConfig, period }) {
	const [formattedData, setFormattedData] = useState([]);
	function monthSort(a, b) {
		const splitA = a.split("-");
		const splitB = b.split("-");
		const yearA = parseInt(splitA[0], 10);
		const monthA = parseInt(splitA[1], 10);
		const yearB = parseInt(splitB[0], 10);
		const monthB = parseInt(splitB[1], 10);

		if (yearA < yearB) return -1;
		if (yearA > yearB) return 1;
		return monthA - monthB;
	}

	useEffect(() => {
		const aggregate = (data, timeKey, dataKey) => {
			const result = {};
			data.forEach((item) => {
				const date = new Date(item[timeKey]);

				let key;
				switch (period) {
					case "trailing30":
					case "daily":
					case "weekly":
						key = date.toISOString().split("T")[0];
						break;
					case "month":
					case "trailing13":
						key = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}`;
						break;
					case "year":
						key = `${date.getUTCFullYear()}`;
						break;
					default:
						key = date.toISOString().split("T")[0];
				}

				if (!result[key]) {
					result[key] = { kWh: 0 };
				}
				result[key].kWh += item[dataKey];
			});
			return result;
		};

		if (!dataList) {
			return;
		}

		let aggregatedLists = [];
		let measuredAggregated = [];
		let expectedAggregated = [];
		for (let i = 0; i < dataList.length; i++) {
			let newData = aggregate(dataList[i].data, dataList[i].timeKey, dataList[i].dataKey);
			aggregatedLists.push(newData);
			if (dataList[i].label === "Measured") {
				measuredAggregated = newData;
			} else if (dataList[i].label === "UW Expected") {
				expectedAggregated = newData;
			}
		}
		let combinedData = {};
		Object.keys(measuredAggregated).forEach((key) => {
			combinedData[key] = {
				Measured: measuredAggregated[key].kWh,
				"UW Expected": expectedAggregated[key] ? expectedAggregated[key].kWh : 0,
			};
			for (let i = 0; i < dataList.length; i++) {
				if (dataList[i].label === "Measured" || dataList[i].label === "UW Expected") {
					continue;
				} else {
					combinedData[key] = {
						...combinedData[key],
						[dataList[i].label]: aggregatedLists[i][key] ? aggregatedLists[i][key].kWh : 0,
					};
				}
			}
		});

		Object.keys(expectedAggregated).forEach((key) => {
			if (!combinedData[key]) {
				combinedData[key] = {
					Measured: 0,
					"UW Expected": expectedAggregated[key] ? expectedAggregated[key].kWh : 0,
				};
				for (let i = 0; i < dataList.length; i++) {
					if (dataList[i].label === "Measured" || dataList[i].label === "UW Expected") {
						continue;
					} else {
						combinedData[key] = {
							...combinedData[key],
							[dataList[i].label]: aggregatedLists[i][key] ? aggregatedLists[i][key].kWh : 0,
						};
					}
				}
			}
		});

		switch (period) {
			case "month":
				const years = Object.keys(combinedData).map((key) => key.split("-")[0]);
				const minYear = Math.min(...years);
				const maxYear = Math.max(...years);

				for (let year = minYear; year <= maxYear; year++) {
					for (let month = 1; month <= 12; month++) {
						const key = `${year}-${month}`;
						if (!combinedData[key]) {
							combinedData[key] = {};

							for (let i = 0; i < dataList.length; i++) {
								combinedData[key][dataList[i].label] = 0;
							}
						}
					}
				}
				setFormattedData(
					Object.keys(combinedData)
						.map((key) => ({
							period: key,
							...combinedData[key],
						}))
						.sort((a, b) => monthSort(a.period, b.period))
				);

				return;
			case "trailing13":
				const allPeriods = Object.keys(combinedData);
				const newestPeriod = new Date(Math.max(...allPeriods.map((d) => new Date(d))));
				const last13Periods = [];

				for (let i = 0; i < 13; i++) {
					const month = newestPeriod.getMonth() - i;
					const year = newestPeriod.getFullYear();

					const adjustedYear = year + Math.floor(month / 12);
					const adjustedMonth = (((month % 12) + 12) % 12) + 1;

					const key = `${adjustedYear}-${adjustedMonth}`;
					last13Periods.push(key);
				}

				last13Periods.forEach((key) => {
					if (!combinedData[key]) {
						combinedData[key] = {};
						for (let i = 0; i < dataList.length; i++) {
							combinedData[key][dataList[i].label] = 0;
						}
					}
				});

				setFormattedData(
					last13Periods.reverse().map((key) => ({
						period: key,
						...combinedData[key],
					}))
				);
				return;
			default:
				break;
		}

		setFormattedData(
			Object.keys(combinedData)
				.map((key) => ({
					period: key,
					...combinedData[key],
				}))
				.sort((a, b) => monthSort(a.period, b.period))
		);
	}, [dataList, period]);

	return (
		<ResponsiveContainer width="100%" height={250}>
			<ComposedChart
				data={formattedData}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="period" />
				<YAxis />
				<Tooltip content={<CustomTooltip graphConfig={graphConfig} />} />
				<Legend />

				{graphConfig &&
					[...graphConfig]
						.sort((a, b) => {
							if (a.graphType === "bar" && b.graphType === "line") return -1;
							if (a.graphType === "line" && b.graphType === "bar") return 1;
							return 0;
						})
						.map((config) => {
							let parentConfig = null;
							if (config.parentLabel) {
								const parent = graphConfig.find((parentConfig) => parentConfig.label === config.parentLabel);
								if (!parent || !parent.visible) {
									return null;
								} else {
									parentConfig = parent;
								}
							} else if (!config.visible) {
								return null;
							}
							if (parentConfig ? parentConfig.graphType === "bar" : config.graphType === "bar") {
								return (
									<Bar
										dataKey={config.label}
										fill={config.fillColor}
										name={config.label}
										cursor="pointer"
										key={config.label}
										stackId={config.stackId}
									/>
								);
							} else if (parentConfig ? parentConfig.graphType === "line" : config.graphType === "line") {
								return (
									<Line
										dataKey={config.label}
										stroke={config.fillColor}
										strokeWidth={4}
										name={config.label}
										cursor="pointer"
										dot={{ r: 4, fill: "#FFFFFF" }}
										activeDot={{ r: 8 }}
										key={config.label}
									/>
								);
							} else {
								return null;
							}
						})}
			</ComposedChart>
		</ResponsiveContainer>
	);
}
