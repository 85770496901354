import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useMemo, useState } from "react";
import { MonitoringRow } from "./MonitoringRow";
import EnvironmentalImpact from "../FleetAndSite/EnvironmentalImpact";
import DraggableHeaders from "./DraggableHeaders";
import TicketsModal from "../FleetAndSite/TicketsModal";
import { BsSearch } from "react-icons/bs";
import { GraphModal } from "../Graphs/GraphModal";
import { FaChevronLeft, FaChevronRight, FaFileCsv } from "react-icons/fa";
import { MdOutlineSolarPower } from "react-icons/md";
import FilterComponent from "../Filters/FilterComponent";
import MyGraph from "../Graphs/MyGraph";

const getHeaders = () => [
	{ id: "title", content: "Client Name - Site Name" },
	{ id: "alerts", content: "Alerts" },
	{ id: "capacity", content: "DC Capacity" },
	{ id: "energy-yesterday", content: "Energy Yesterday" },
	{ id: "energy-lifetime", content: "Energy Lifetime" },
	{ id: "trailing-7", content: "Trailing 7 Days Generation" },
	{ id: "trailing-30", content: "Trailing 30 Days Generation" },
	{ id: "trailing-12", content: "Trailing 12 Months Generation" },
];

export default function MonitoringDashboard({ sidebarOpen, api, token, isCommercial }) {
	const navigate = useNavigate();
	const { user } = useAuth0();
	const [fetchingData, setFetchingData] = useState(true);
	const [sites, setSites] = useState([]);
	const [energyLifetime, setEnergyLifetime] = useState(0);
	const [aggregatedParents, setAggregatedParents] = useState([]);
	const [children, setChildren] = useState(null);
	const [totalInstalledKw, setTotalInstalledKw] = useState(0);
	const [sevenDayProduction, setSevenDayProduction] = useState(0);
	const [headers, setHeaders] = useState(getHeaders());
	const [sortedSites, setSortedSites] = useState([]);
	const [allTickets, setAllTickets] = useState([]);
	const [showTicketsModal, setShowTicketsModal] = useState(false);
	const [showRotateModal, setShowRotateModal] = useState(false);
	const [filterText, setFilterText] = useState("");
	const [sortByHeader, setSortByHeader] = useState("none"); // none or titleId + ascending or descending
	const [contractorFilter, setContractorFilter] = useState("all");
	const [contractorOptions, setContractorOptions] = useState([]);
	const [contractorInfo, setContractorInfo] = useState([]);
	const [filteredTickets, setFilteredTickets] = useState([]);
	const [filteredSites, setFilteredSites] = useState([]);
	const [monitoringSubscription, setMonitoringSubscription] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(-1);
	const [sitesOnPage, setSitesOnPage] = useState([]);
	const [unEnteredFilterText, setUnEnteredFilterText] = useState("");
	const [portfolioOptions, setPortfolioOptions] = useState([]);
	const [portfolioFilter, setPortfolioFilter] = useState("");
	const [noSitesFound, setNoSitesFound] = useState(false);
	const [monitoringOptions, setMonitoringOptions] = useState([]);
	const [monitoringFilter, setMonitoringFilter] = useState("All");
	const ptoOptions = ["All", "PTO Approved", "No PTO"];
	const [ptoFilter, setPtoFilter] = useState("All");
	const [filteredInverterData, setFilteredInverterData] = useState([]);

	const SITES_PER_PAGE = 10;

	const removeAutonums = (s) => {
		const regex = /(-\s?\d+)+$/;
		return s.replace(regex, "");
	};

	const memoizedGraphData = useMemo(() => {
		let graphData = [
			{
				name: "Measured kWh",
				label: "Measured",
				data: aggregatedParents.measuredData,
				timeKey: "Date",
				dataKey: "kWh",
				graphConfig: {
					visible: true,
					graphType: "bar",
					fillColor: "#82ca9d",
				},
			},
		];

		if (user.email === "ps@1stle.com" || user.email === "ess@1stle.com" || user.email === "costless@1stle.com") {
			graphData.push({
				name: "UW Expected",
				label: "UW Expected",
				data: aggregatedParents.expectedData,
				timeKey: "Date",
				dataKey: "kWh",
				graphConfig: {
					visible: true,
					graphType: "bar",
					fillColor: "#8884d8",
				},
			});
		}

		if (user.email !== "costless@1stle.com") {
			graphData.push({
				name: "As Built",
				label: "As Built",
				data: aggregatedParents.asBuiltData,
				timeKey: "Date",
				dataKey: "kWh",
				graphConfig: {
					visible: false,
					graphType: "bar",
					fillColor: "#ffa07a",
				},
			});
		}

		if (filteredInverterData && filteredInverterData.length > 0) {
			graphData.push({
				name: "Inverters",
				label: "Inverters",
				data: filteredInverterData,
				timeKey: "Date",
				dataKey: "kWh",
				graphConfig: {
					visible: false,
					graphType: "bar",
					fillColor: "#95e89b",
					stackId: "Measured",
					parentLabel: "Measured",
				},
			});
		}

		return graphData;
	}, [aggregatedParents.measuredData, aggregatedParents.asBuiltData, aggregatedParents.expectedData, filteredInverterData, user.email]);

	useEffect(() => {
		if (api === "" || !token || isCommercial) {
			return;
		}

		try {
			fetch(api + "/installation", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					userEmail: user.email,
				}),
				mode: "cors",
			})
				.then((res) => {
					return res.json();
				})
				.then((res) => {
					if (!res.monitoringSubscription) {
						navigate("/installation");
					}
					setMonitoringSubscription(res.monitoringSubscription);
				});
		} catch (error) {}
	}, [api, token, user.email, navigate, isCommercial]);

	useEffect(() => {
		if (!user || !api || !token) {
			return;
		}
		setFetchingData(true);
		fetch(api + "/monitoring/all", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({ email: user.email }),
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
			})
			.then((jsonData) => {
				if (jsonData) {
					setMonitoringOptions(jsonData.monitoringOptions);
					setSites(jsonData.sites);
					if (!jsonData.sites || (jsonData.sites && jsonData.sites.length === 0)) {
						setNoSitesFound(true);
					}
					if (jsonData.contractorOptions && jsonData.contractorOptions.length > 0) {
						setContractorOptions(["All", ...jsonData.contractorOptions, "1st Light Energy"]);
					}
					setContractorInfo(jsonData.contractorInfo);
					setAllTickets(jsonData.allTickets);
					setTotalPages(Math.ceil(jsonData.sites.length / SITES_PER_PAGE));
					setPortfolioOptions(jsonData.portfolioOptions);
				}
			});
	}, [api, token, user]);

	useEffect(() => {
		let processedSites = [...sites];
		if (ptoFilter !== "All") {
			if (ptoFilter === "PTO Approved") {
				processedSites = processedSites.filter((filterData) => filterData.ptoOptions.includes(true));
			} else {
				processedSites = processedSites.filter((filterData) => filterData.ptoOptions.includes(false));
			}
		}
		if (monitoringFilter !== "All") {
			processedSites = processedSites.filter((filterData) => filterData.presentMonitoringTypes.includes(monitoringFilter));
		}
		if (contractorFilter === "1st Light Energy") {
			processedSites = processedSites.filter(
				(site) => !site.x_contractors || site.x_contractors === false || (Array.isArray(site.x_contractors) && site.x_contractors.length === 0)
			);
		} else if (contractorFilter !== "All") {
			let usedContractorIds = contractorInfo
				.filter((filterData) => {
					if (filterData.x_studio_alias === contractorFilter) {
						return true;
					}
					if (!filterData.x_studio_alias) {
						const email = filterData.x_name;
						const domainMatch = email.match(/@([^@.]+(?:\.[^@.]+)*)\.[^.]+$/);
						const domain = domainMatch ? domainMatch[1] : null;
						return domain === contractorFilter;
					}

					return false;
				})
				.map((mapData) => mapData.id);
			if (usedContractorIds.length > 0) {
				processedSites = processedSites.filter((site) => {
					if (!site.x_contractors) {
						return false;
					}
					return site.x_contractors.some((contractorId) => usedContractorIds.includes(contractorId));
				});
			}
		}

		if (filterText !== "") {
			processedSites = processedSites.filter((site) => {
				if (removeAutonums(site.name).toLowerCase().includes(filterText.toLowerCase())) {
					return true;
				}
				if (site.x_studio_customer_portal_alias && site.x_studio_customer_portal_alias.toLowerCase().includes(filterText.toLowerCase())) {
					return true;
				}

				return false;
			});
		}
		if (portfolioFilter !== "" && portfolioFilter !== "All") {
			if (portfolioFilter === "No Portfolio Set") {
				processedSites = processedSites.filter((site) => !site.x_studio_portfolio_1);
			} else {
				processedSites = processedSites.filter((site) => site.x_studio_portfolio_1 === portfolioFilter);
			}
		}
		let newInverterData = [];
		processedSites.forEach((site) => {
			newInverterData.push(...site.solarEdgeData, ...site.solisInverterData);
		});
		setFilteredInverterData(newInverterData);
		setFilteredSites(processedSites);

		// Step 2: Sort
		switch (sortByHeader) {
			case "title-desc":
				processedSites.sort((a, b) => b.name.localeCompare(a.name));
				break;
			case "title-asc":
				processedSites.sort((a, b) => a.name.localeCompare(b.name));
				break;
			case "energy-lifetime-desc":
				processedSites.sort((a, b) => b.energyLifetime - a.energyLifetime);
				break;
			case "energy-lifetime-asc":
				processedSites.sort((a, b) => a.energyLifetime - b.energyLifetime);
				break;
			case "capacity-desc":
				processedSites.sort((a, b) => b.totalDCCapacity - a.totalDCCapacity);
				break;
			case "capacity-asc":
				processedSites.sort((a, b) => a.totalDCCapacity - b.totalDCCapacity);
				break;
			case "trailing-7-desc":
				processedSites.sort((a, b) => b.trailing7DaysPercent - a.trailing7DaysPercent);
				break;
			case "trailing-7-asc":
				processedSites.sort((a, b) => a.trailing7DaysPercent - b.trailing7DaysPercent);
				break;
			case "trailing-30-desc":
				processedSites.sort((a, b) => b.trailing30DaysPercent - a.trailing30DaysPercent);
				break;
			case "trailing-30-asc":
				processedSites.sort((a, b) => a.trailing30DaysPercent - b.trailing30DaysPercent);
				break;
			case "trailing-12-desc":
				processedSites.sort((a, b) => b.trailing12Percent - a.trailing12Percent);
				break;
			case "trailing-12-asc":
				processedSites.sort((a, b) => a.trailing12Percent - b.trailing12Percent);
				break;
			case "energy-yesterday-desc":
				processedSites.sort((a, b) => b.yesterdayEnergy - a.yesterdayEnergy);
				break;
			case "energy-yesterday-asc":
				processedSites.sort((a, b) => a.yesterdayEnergy - b.yesterdayEnergy);
				break;
			case "alerts-desc":
				processedSites.sort((a, b) => b.alerts.length - a.alerts.length);
				break;
			case "alerts-asc":
				processedSites.sort((a, b) => a.alerts.length - b.alerts.length);
				break;
			default:
				break;
		}
		// Update state
		setTotalPages(Math.ceil(processedSites.length / SITES_PER_PAGE));
		setSortedSites(processedSites);
	}, [contractorFilter, contractorInfo, filterText, monitoringFilter, portfolioFilter, ptoFilter, sites, sortByHeader]);

	useEffect(() => {
		if (sortedSites.length > 0) {
			setFetchingData(false);
		}
	}, [sortedSites]);

	useEffect(() => {
		let processedSites = [...sortedSites];
		const startIndex = (page - 1) * SITES_PER_PAGE;
		const endIndex = startIndex + SITES_PER_PAGE;
		processedSites = processedSites.slice(startIndex, endIndex);
		setSitesOnPage(processedSites);
	}, [page, sortedSites]);

	useEffect(() => {
		let parents = [...filteredSites];
		let parentMeasured = [];
		let parentExpected = [];
		let parentAsBuilt = [];
		let newTotalInstalledKw = 0;
		let hasAsBuilt = false;
		let lastSevenDaysProduction = 0;
		let lastSevenDaysExpected = 0;
		let formattedChildren = [];

		parents.forEach((parent) => {
			if (!hasAsBuilt) {
				if (parent.hasAsBuilt === true) {
					hasAsBuilt = true;
				}
			}
			lastSevenDaysProduction += parent.measured7Days;
			lastSevenDaysExpected += parent.expected7Days;

			parent.expectedTableData.forEach((expected) => {
				const date = new Date(expected.year, expected.month - 1);
				const formattedDate = date.toISOString().slice(0, 10);

				parentExpected.push({
					Date: formattedDate,
					kWh: expected.value,
				});
			});

			parent.children.forEach((child) => {
				if (child.x_studio_meter_kw) {
					newTotalInstalledKw += child.x_studio_meter_kw;
				}

				let newChild = {
					name: child.name,
					meterKw: child.x_studio_meter_kw,
					monitorMultiplier: child.x_studio_monitor_multiplier,
					measuredData: [],
					expectedData: [],
					asBuiltData: [],
				};

				child.monitoringData.forEach((data) => {
					if (monitoringFilter !== "All") {
						if (monitoringFilter.toLowerCase() !== data.type.toLowerCase()) {
							return;
						}
					}
					if (ptoFilter !== "All") {
						if (ptoFilter === "PTO Approved") {
							if (!child.x_studio_pto_approved_int) {
								return;
							}
						} else {
							if (child.x_studio_pto_approved_int) {
								return;
							}
						}
					}

					newChild.measuredData.push({
						Date: data.ts,
						kWh:
							child.x_studio_monitor_multiplier > 0
								? Math.ceil(data.kwh_measured * child.x_studio_monitor_multiplier)
								: Math.ceil(data.kwh_measured),
					});

					newChild.asBuiltData.push({
						Date: data.ts,
						kWh: Math.floor(data.kwh_as_built),
					});

					parentMeasured.push({
						Date: data.ts,
						kWh:
							child.x_studio_monitor_multiplier > 0
								? Math.ceil(data.kwh_measured * child.x_studio_monitor_multiplier)
								: Math.ceil(data.kwh_measured),
					});

					parentAsBuilt.push({
						Date: data.ts,
						kWh: Math.floor(data.kwh_as_built),
					});
				});

				if (!child.expectedTableData) {
					child.expectedTableData = [];
				}

				newChild.monitoringType = parent.monitoringType;
				formattedChildren.push(newChild);
			});
		});

		let newMeasuredEnergy = parentMeasured.reduce((acc, obj) => acc + obj.kWh, 0);
		if (filteredInverterData) {
			filteredInverterData.forEach((data) => {
				newMeasuredEnergy += Math.ceil(data.kWh);
			});
		}
		parentMeasured.sort((a, b) => new Date(a.Date) - new Date(b.Date));
		parentExpected.sort((a, b) => new Date(a.Date) - new Date(b.Date));
		parentAsBuilt.sort((a, b) => new Date(a.Date) - new Date(b.Date));
		let parentFormatted = {
			measuredData: parentMeasured,
			expectedData: parentExpected,
			asBuiltData: parentAsBuilt,
			hasAsBuilt: hasAsBuilt,
		};
		setTotalInstalledKw(newTotalInstalledKw);
		setAggregatedParents(parentFormatted);
		setEnergyLifetime(newMeasuredEnergy);
		setSevenDayProduction((Math.ceil(lastSevenDaysProduction) / Math.floor(lastSevenDaysExpected)) * 100);
		setChildren(formattedChildren);
	}, [filteredInverterData, filteredSites, monitoringFilter, ptoFilter]);

	useEffect(() => {
		let unfilteredTickets = [...allTickets];
		let installationIds = [];

		for (let i = 0; i < sortedSites.length; i++) {
			installationIds.push(sortedSites[i].id);
			for (let j = 0; j < sortedSites[i].children.length; j++) {
				installationIds.push(sortedSites[i].children[j].id);
			}
		}
		unfilteredTickets = unfilteredTickets.filter((filterData) => installationIds.includes(filterData.x_studio_installation[0]));
		setFilteredTickets(unfilteredTickets);
	}, [sortedSites, allTickets]);

	const changeContractFilter = (e) => {
		setContractorFilter(e.target.value);
	};

	const changePortfolioFilter = (e) => {
		setPortfolioFilter(e.target.value);
	};

	const changeMonitoringFilter = (e) => {
		setMonitoringFilter(e.target.value);
	};

	const changePTOFilter = (e) => {
		setPtoFilter(e.target.value);
	};

	return (
		<>
			<div
				className={`${
					user.email === "ps@1stle.com"
						? "bg-PSBackgroundImage"
						: user.email === "ess@1stle.com"
						? "bg-ESSBackgroundImage"
						: isCommercial
						? "bg-CommercialBackgroundImage"
						: "bg-1stleBackgroundImage"
				} fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover`}
			/>
			{showTicketsModal ? <TicketsModal allTickets={filteredTickets} user={user} showTitle={true} setShowTicketsModal={setShowTicketsModal} /> : null}

			<div className={`${sidebarOpen ? "md:ml-64" : "md:ml-10"} z-[100] `}>
				<div className="text-sm text-start text-gray-800 m-10 overflow-hidden  ">
					<div className="flex justify-center items-center pb-10">
						{user.email === "ps@1stle.com" ? (
							<img className="h-32" src={process.env.REACT_APP_PUBLIC_STORAGE_LOGO_URL} alt="PUBLIC STORAGE" />
						) : user.email === "ess@1stle.com" ? (
							<img className="h-32" src={process.env.REACT_APP_EXTRA_SPACE_STORAGE_LOGO_URL} alt="EXTRA SPACE STORAGE" />
						) : null}
					</div>

					{fetchingData ? (
						noSitesFound ? (
							<div className="mt-4 p-4 bg-white flex justify-center items-center">
								<div className="  flex justify-center items-center flex-col p-5 ">
									<MdOutlineSolarPower size={"6rem"} />
									<p className="mt-2 text-xl font-bold">No Sites Found</p>
									<p className="mt-1">Please try again later as your account may be in the process of getting set up.</p>
								</div>
							</div>
						) : (
							<div className="mt-4 p-4 bg-white flex justify-center items-center">
								<div className="  flex justify-center items-center flex-col p-5 ">
									<img src={process.env.REACT_APP_LOADING_SVG} alt="Loading..." />
									<p className="mt-2 font-bold">Loading Your Data...</p>
								</div>
							</div>
						)
					) : isCommercial ? (
						<div className="bg-slate-50 bg-opacity-95">
							<div className="mt-4 p-4  bg-opacity-95 rounded-lg">
								<div className="flex justify-between items-center">
									<div
										className="p-3 flex items-center gap-2 bg-white w-32 cursor-pointer border border-black rounded-lg select-none"
										onClick={() => navigate("/monitoring/export")}>
										<FaFileCsv className="text-2xl cursor-pointer" />
										<p className="cursor-pointer">Export</p>
									</div>
									<div className="flex gap-2 items-end justify-end w-full">
										<FilterComponent
											title={"Monitoring Types"}
											changeFilter={changeMonitoringFilter}
											filter={monitoringFilter}
											options={monitoringOptions}
											useVerticalLayout
										/>

										<FilterComponent
											title={"Contractors"}
											changeFilter={changeContractFilter}
											filter={contractorFilter}
											options={contractorOptions}
											useVerticalLayout
											blankOptionText="1st Light Energy"
										/>

										<FilterComponent
											title={"PTO"}
											changeFilter={changePTOFilter}
											filter={ptoFilter}
											options={ptoOptions}
											requiredUserEmail={"ps@1stle.com"}
											userEmail={user.email}
											useVerticalLayout
										/>

										<FilterComponent
											title={"Portfolios"}
											changeFilter={changePortfolioFilter}
											filter={portfolioFilter}
											options={portfolioOptions}
											useVerticalLayout
											blankOptionText="No Portfolio Set"
										/>
									</div>
								</div>
								<div className="text-center w-full mb-4">
									<p className="text-2xl md:text-3xl font-bold ">Fleet</p>
								</div>
								<div className="gap-4 h-full md:flex">
									<div className="w-full flex flex-col border border-black  rounded-lg bg-black overflow-hidden mb-4 md:mb-0">
										<p className="text-lg text-white p-2 font-bold text-center">General Information</p>
										<div className="p-4 flex flex-col gap-2 bg-white h-full">
											<div className="flex justify-between border-b-2 border-gray">
												<p className="text-xs md:text-sm font-bold">Total kW Installed</p>
												<p>{Number(totalInstalledKw.toFixed(2)).toLocaleString("en-US") + " "} kW</p>
											</div>
											<div className="flex justify-between border-b-2 border-gray">
												<p className="text-xs md:text-sm font-bold">Total kWhs Produced</p>
												<p>{Number(energyLifetime).toLocaleString("en-US") + " "}kWh</p>
											</div>
											<div className="flex justify-between border-b-2 border-gray">
												<p className="text-xs md:text-sm font-bold">Number of Sites</p>
												<p>{Number(sortedSites.length).toLocaleString("en-US")}</p>
											</div>
											<div className="flex justify-between border-b-2 border-gray">
												<p className="text-xs md:text-sm font-bold">Number of Tickets</p>

												<p
													onClick={() => setShowTicketsModal(true)}
													className="cursor-pointer text-blue-500 hover:text-blue-700 underline">
													{filteredTickets ? Number(filteredTickets.length).toLocaleString("en-US") : 0}
												</p>
											</div>
											<div className="flex justify-between border-b-2 border-gray">
												<p className="text-xs md:text-sm font-bold">7 Day Production</p>
												<p>{Math.ceil(sevenDayProduction).toLocaleString("en-US")}%</p>
											</div>
										</div>
									</div>

									<EnvironmentalImpact generatedToDate={energyLifetime} />
								</div>
								<div
									className={`p-3 mt-4 sm:hidden flex items-center justify-center w-auto cursor-pointer ${
										user.email === "ps@1stle.com"
											? "bg-[#FFAC1C] hover:bg-orange-500"
											: user.email === "ess@1stle.com"
											? "bg-lime-400 hover:bg-lime-500"
											: isCommercial
											? "bg-[#FFEA00] hover:bg-yellow-400"
											: "bg-blue-500 hover:bg-blue-600"
									}`}
									onClick={() => setShowRotateModal(true)}>
									<p className="font-bold cursor-pointer">View Graphs</p>
								</div>
								{(aggregatedParents && aggregatedParents.measuredData && aggregatedParents.measuredData.length > 0) ||
								filteredInverterData.length > 0 ? (
									<div className="text-color-black w-full hidden sm:block mt-0 sm:mt-4">
										<MyGraph
											containerTitle="Trailing 13 Months"
											timeFilter={"trailing13"}
											startExpanded={true}
											dataList={memoizedGraphData}
										/>
									</div>
								) : null}
								{showRotateModal ? <GraphModal setShowRotateModal={setShowRotateModal} /> : null}
							</div>

							<div className="mt-4 px-4 pb-2 bg-opacity-95 rounded-lg">
								<p className="text-2xl md:text-3xl font-bold mb-4">Sites</p>

								<form
									onSubmit={(e) => {
										e.preventDefault();
										setFilterText(unEnteredFilterText);
										setPage(1);
									}}
									className="flex w-full justify-center items-center mb-2 border border-black rounded-lg">
									<input
										type="text"
										className="p-2 w-full rounded-l-lg"
										placeholder="Search for sites"
										value={unEnteredFilterText}
										onChange={(e) => setUnEnteredFilterText(e.target.value)}
									/>
									<button
										type="button"
										onClick={(e) => {
											e.preventDefault();
											setFilterText(unEnteredFilterText);
											setPage(1);
										}}
										className="border rounded-r-lg border-l-black p-3 bg-white">
										<BsSearch />
									</button>
								</form>
								<div className="w-full grid grid-cols-1 laptop:grid-cols-9">
									<div className="col-span-9 border-b border-black hidden laptop:block">
										<DraggableHeaders items={headers} setItems={setHeaders} setSortByHeader={setSortByHeader} sortByHeader={sortByHeader} />
									</div>
									{fetchingData
										? null
										: sitesOnPage && sitesOnPage.length > 0
										? sitesOnPage.map((mapData, index) => {
												return (
													<div
														key={mapData.id}
														className={`${
															index === SITES_PER_PAGE - 1
																? "rounded-b-lg"
																: index === 0
																? "rounded-t-lg laptop:rounded-t-none"
																: ""
														} border-l border-r border-t border-b border-black grid grid-cols-1 laptop:grid-cols-9 col-span-1 laptop:col-span-9`}>
														<MonitoringRow
															site={mapData}
															bgColor={index % 2 === 0 ? "bg-white" : "bg-gray-200"}
															headersOrder={headers}
															isLast={SITES_PER_PAGE - 1 === index ? true : false}
														/>
													</div>
												);
										  })
										: null}
								</div>
								<div className="flex justify-center mt-1 ">
									<div className="flex justify-center items-enter gap-2  p-2">
										<div>
											<button
												onClick={() => setPage(1)}
												className={`flex border border-black cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
            ${page > 1 ? "bg-blue-500 text-blue-500" : "text-black"}
            ${page > 1 ? "bg-white hover:bg-blue-500 hover:text-white" : "text-gray-300 bg-white cursor-not-allowed"}`}
												disabled={page <= 1}>
												<FaChevronLeft size="0.6rem" />
												<FaChevronLeft size="0.6rem" className="-translate-x-1/2" />
											</button>
										</div>
										<div>
											<button
												onClick={() => setPage((prev) => prev - 1)}
												className={`flex  border border-black  cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
            ${page > 1 ? "bg-blue-500 text-blue-500" : "text-black"}
            ${page > 1 ? "bg-white hover:bg-blue-500 hover:text-white" : "text-gray-300 bg-white cursor-not-allowed"}`}
												disabled={page <= 1}>
												<FaChevronLeft size="0.6rem" />
											</button>
										</div>
										<div className="flex border border-black justify-center items-center bg-white p-2 rounded-lg">
											<p>{`${page} / ${totalPages}`}</p>
										</div>

										<div>
											<button
												onClick={() => setPage((prev) => prev + 1)}
												className={`flex border border-black cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
            ${page < totalPages ? "bg-blue-500 text-blue-500" : "text-black"}
            ${page < totalPages ? "bg-white hover:bg-blue-500 hover:text-white" : "text-gray-300 bg-white cursor-not-allowed"}`}
												disabled={page >= totalPages}>
												<FaChevronRight size="0.6rem" />
											</button>
										</div>
										<div>
											<button
												onClick={() => setPage(totalPages)}
												className={`flex border border-black cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
            ${page < totalPages ? "bg-blue-500 text-blue-500" : "text-black"}
            ${page < totalPages ? "bg-white hover:bg-blue-500 hover:text-white" : "text-gray-300 bg-white cursor-not-allowed"}`}
												disabled={page >= totalPages}>
												<FaChevronRight size="0.6rem" />
												<FaChevronRight size="0.6rem" className="-translate-x-1/2" />
											</button>
										</div>
									</div>
								</div>
								<p className="mt-2 text-center text-xs text-underline">Data updates occur daily at 2 AM PT, adding the previous day's data</p>
							</div>
						</div>
					) : monitoringSubscription && sites.length > 0 ? (
						navigate("/monitoring/" + sites[0].id)
					) : null}
				</div>
			</div>
		</>
	);
}
