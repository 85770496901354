import { useEffect, useState, Fragment } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate, useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingCircle from "../../Utility/LoadingCircle";
import { FaFileCsv } from "react-icons/fa";
import EnvironmentalImpact from "../FleetAndSite/EnvironmentalImpact";
import { Link } from "react-router-dom";
import TicketsModal from "../FleetAndSite/TicketsModal";
import { GraphModal } from "../Graphs/GraphModal";
import InvertersGrid from "../Inverters/InvertersGrid";
import AlertIconList from "../Alerts/AlertIconList";
import ExpectationsCalculator from "./ExpectationsCalculator";
import CustomizableSiteAlias from "./CustomizableSiteAlias";
import MyGraph from "../Graphs/MyGraph";

export default function Monitoring({ sidebarOpen, api, token, isCommercial }) {
	const navigate = useNavigate();
	const [parent, setParent] = useState(null);
	const [children, setChildren] = useState(null);
	const { user } = useAuth0();
	const [fetchingData, setFetchingData] = useState(true);
	const { id } = useParams();
	const [allTickets, setAllTickets] = useState([]);
	const [showTicketsModal, setShowTicketsModal] = useState(false);
	const [showRotateModal, setShowRotateModal] = useState(false);
	const [inverters, setInverters] = useState([]);
	const [panelCleaningDate, setPanelCleaningDate] = useState("");
	const [systemCheckDate, setSystemCheckDate] = useState("");
	const [expectedTableData, setExpectedTableData] = useState([]);
	const [dailyExpectedData, setDailyExpectedData] = useState([]);
	const [emptyExpectedTable, setEmptyExpectedTable] = useState(false);
	const [solarEdgeInverters, setSolarEdgeInverters] = useState([]);
	const [inverterData, setInverterData] = useState([]);

	useEffect(() => {
		if (parent) {
			document.title = `${parent.x_studio_customer_portal_alias ? parent.x_studio_customer_portal_alias : parent.name} | Customer Portal`;
		} else {
			document.title = "Customer Portal";
		}
		return () => {
			document.title = "Customer Portal";
		};
	}, [parent]);

	function sumKWh(items) {
		return items.reduce((total, item) => {
			return total + (item.kWh || 0);
		}, 0);
	}

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (!user || !api || !token) {
			return;
		}
		setFetchingData(true);
		let dateForInverters = new Date();
		fetch(api + "/monitoring/" + id, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			signal,
			body: JSON.stringify({ email: user.email, dateForInverters }),
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					setFetchingData(false);
				}
			})
			.then((jsonData) => {
				if (jsonData) {
					setInverters(jsonData.inverters ? jsonData.inverters : []);
					let solisData = jsonData.solisData ? jsonData.solisData : [];
					let solarEdgeData = jsonData.seData ? jsonData.seData : [];
					setInverterData([...solisData, ...solarEdgeData]);
					setSolarEdgeInverters(jsonData.seInverters ? jsonData.seInverters : []);
					let newParent;
					newParent = jsonData.parent;
					const systemTurnOnDate = new Date(jsonData.parent.x_studio_system_turned_on_date_p);
					setAllTickets(jsonData.allTickets ? jsonData.allTickets : []);
					if (jsonData.children) {
						let parentFormatted = { measuredData: [], expectedData: [], asBuiltData: [] };
						let parentMeasured = [];
						let parentExpected = [];
						let parentAsBuilt = [];
						let formattedChildren = [];
						for (let i = 0; i < jsonData.children.length; i++) {
							if (!jsonData.children[i].x_studio_locust_id && !jsonData.children[i].x_studio_landis_id) {
								continue;
							}
							let newChild = {};
							newChild["name"] = jsonData.children[i].name;
							newChild.meterKw = jsonData.children[i].x_studio_meter_kw;
							newChild.monitorMultiplier = jsonData.children[i].x_studio_monitor_multiplier;
							newChild.meter_percentage = jsonData.children[i].meter_percentage;

							const shiftDateBackOneDay = (dateStr) => {
								let date = new Date(dateStr);
								date.setDate(date.getDate() - 1);
								return date.toISOString();
							};

							const today = new Date().toISOString().split("T")[0];
							const hasTodayData = jsonData.children[i].monitoringData.some((mapData) => mapData.ts.split("T")[0] === today);

							let newMeasuredData = jsonData.children[i].monitoringData.map((mapData) => {
								return newChild.monitorMultiplier > 0
									? {
											Date: hasTodayData ? shiftDateBackOneDay(mapData.ts) : mapData.ts,
											kWh: Math.ceil(mapData.kwh_measured * newChild.monitorMultiplier),
									  }
									: {
											Date: hasTodayData ? shiftDateBackOneDay(mapData.ts) : mapData.ts,
											kWh: Math.ceil(mapData.kwh_measured),
									  };
							});
							let newExpectedData = [];
							jsonData.expectedTableData.forEach((expected) => {
								const daysInMonth = new Date(expected.year, expected.month, 0).getDate();
								const dailyValue = expected.value / daysInMonth;
								let totalMeterKw = jsonData.children.reduce((sum, child) => sum + child.x_studio_meter_kw, 0);

								for (let day = 1; day <= daysInMonth; day++) {
									const date = new Date(expected.year, expected.month - 1, day).toISOString().split("T")[0];
									const isAfterTurnOnDate = new Date(expected.year, expected.month - 1, day) >= systemTurnOnDate;
									if (date < today && isAfterTurnOnDate) {
										newExpectedData.push({
											Date: date,
											kWh: dailyValue * jsonData.children[i].meter_percentage,
										});
									}
								}
							});
							let newAsBuiltData = jsonData.children[i].monitoringData.map((mapData) => {
								return {
									Date: hasTodayData ? shiftDateBackOneDay(mapData.ts) : mapData.ts,
									kWh: Math.floor(mapData.kwh_as_built),
								};
							});
							newChild.measuredData = newMeasuredData;
							newChild.expectedData = newExpectedData;
							newChild.asBuiltData = newAsBuiltData;
							newChild.monitoringType = newParent.monitoringType;
							parentMeasured = [...parentMeasured, ...newMeasuredData];
							parentExpected = [...parentExpected, ...newExpectedData];
							parentAsBuilt = [...parentAsBuilt, ...newAsBuiltData];
							formattedChildren.push(newChild);
						}

						parentMeasured.sort((a, b) => new Date(a.Date) - new Date(b.Date));
						parentExpected.sort((a, b) => new Date(a.Date) - new Date(b.Date));
						parentAsBuilt.sort((a, b) => new Date(a.Date) - new Date(b.Date));

						parentFormatted.measuredData = parentMeasured;
						parentFormatted.expectedData = parentExpected;
						parentFormatted.asBuiltData = parentAsBuilt;

						newParent = { ...newParent, ...parentFormatted };

						formattedChildren.sort(function (obj1, obj2) {
							return obj2.meterKw - obj1.meterKw;
						});
						setChildren(formattedChildren);
					}

					const systemPTOAproveDate = new Date(jsonData.parent.x_studio_pto_approved_p);
					const systemTurnOnYear = systemTurnOnDate.getFullYear();
					const systemTurnOnMonth = systemTurnOnDate.getMonth() + 1;
					const systemTurnOnDay = systemTurnOnDate.getDate();

					const formattedData = jsonData.expectedTableData.flatMap(({ year, month, value }) => {
						const daysInMonth = new Date(year, month, 0).getDate();
						const today = new Date().toISOString().split("T")[0];

						let dailyValue;
						if (year === systemTurnOnYear && month === systemTurnOnMonth) {
							const remainingDays = daysInMonth - systemTurnOnDay + 1;
							dailyValue = value / remainingDays;
						} else {
							dailyValue = value / daysInMonth;
						}

						return Array.from({ length: daysInMonth }, (_, day) => {
							const date = new Date(year, month - 1, day + 1).toISOString();
							const isBeforeTurnOnDay = year === systemTurnOnYear && month === systemTurnOnMonth && day + 1 < systemTurnOnDay;
							return { Date: date, kWh: isBeforeTurnOnDay ? 0 : dailyValue };
						}).filter(({ Date }) => Date.split("T")[0] < today);
					});

					setParent(newParent);
					setPanelCleaningDate(jsonData.latestPanelCleaning);
					setSystemCheckDate(jsonData.latestSystemCheck);
					setExpectedTableData(jsonData.expectedTableData);
					setDailyExpectedData(formattedData);
					setEmptyExpectedTable(jsonData.emptyExpectedTable);
					setFetchingData(false);
				}
			});
		return () => controller.abort();
	}, [api, id, token, user]);

	function extractLastLetterIfPatternMet(s, index) {
		const match = s.match(/[A-Za-z]$/);
		return match ? match[0] : index;
	}

	const formatDate = (dateString) => {
		if (!dateString) return "";
		const date = new Date(dateString);
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		const year = date.getFullYear();
		return `${month}-${day}-${year}`;
	};

	return (
		<>
			<div
				className={`${
					user.email === "ps@1stle.com"
						? "bg-PSBackgroundImage"
						: user.email === "ess@1stle.com"
						? "bg-ESSBackgroundImage"
						: isCommercial
						? "bg-CommercialBackgroundImage"
						: "bg-1stleBackgroundImage"
				} fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover`}></div>
			{showTicketsModal ? (
				<TicketsModal allTickets={allTickets} user={user} projectId={id} setShowTicketsModal={setShowTicketsModal} isCommercial={isCommercial} />
			) : null}

			{/* <div className=" bg-slate-200/[.29] fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover"></div> */}
			<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
				<div className="m-10 p-4 bg-slate-50 bg-opacity-95">
					<div className={`flex ${!isCommercial ? "justify-end" : "justify-between"}`}>
						{isCommercial ? (
							<div
								className={`p-3 flex items-center justify-center gap-2 w-32 cursor-pointer ${
									user.email === "ps@1stle.com"
										? "bg-[#FFAC1C] hover:bg-orange-500"
										: user.email === "ess@1stle.com"
										? "bg-lime-400 hover:bg-lime-500"
										: "bg-[#FFEA00] hover:bg-yellow-400"
								}`}
								onClick={() => (window.history.state && window.history.state.idx > 0 ? navigate(-1) : navigate("/monitoring"))}>
								<IoArrowBackCircle className="text-2xl cursor-pointer" />
								<p className="cursor-pointer">Go back</p>
							</div>
						) : null}
						{(!fetchingData && children && children.length > 0) || inverterData.length > 0 ? (
							<div
								className="p-3 flex items-center gap-2 bg-white w-32 cursor-pointer border border-black rounded-lg select-none"
								onClick={() => navigate("/monitoring/export/" + id)}>
								<FaFileCsv className="text-3xl cursor-pointer" />
								<p className="cursor-pointer">Export</p>
							</div>
						) : null}
					</div>

					{/* <div className="bg-white p-2 border border-black mt-4 mb-4 sticky ">
					<h2 className="text-center text-2xl font-bold">Historical Data</h2>
				</div> */}
					{fetchingData ? (
						<div className="m-4 flex flex-col justify-center items-center">
							<LoadingCircle />
							<p className="font-bold">Retrieving your data...</p>
						</div>
					) : parent ? (
						<>
							<CustomizableSiteAlias record={parent} setRecord={setParent} user={user} isCommercial={isCommercial} api={api} token={token} />
							<div className="flex gap-4">
								<div className="w-full ">
									<div className=" flex flex-col h-full">
										<div className="gap-4 h-full md:flex">
											<div className="w-full flex flex-col border border-black  rounded-lg bg-black mb-4 md:mb-0">
												<p className="text-lg text-white p-2 font-bold text-center">Site Overview</p>
												<div className="p-4 flex flex-col gap-2 bg-white rounded-b-lg h-full">
													<div className="flex justify-between border-b-2 border-gray gap-2">
														<p className="text-xs md:text-base font-bold">Location</p>
														{parent.google_map_link ? (
															<p
																dangerouslySetInnerHTML={{ __html: parent.google_map_link }}
																className="text-sm md:text-base text-blue-500 hover:text-blue-700 underline"
															/>
														) : (
															"-"
														)}
													</div>
													<div className="flex justify-between border-b-2 border-gray">
														<p className="text-xs md:text-base font-bold">Generated to Date</p>
														<p>
															{Number(parent.energyLifetime.toFixed(1)).toLocaleString("en-US") + " "}
															kWh
														</p>
													</div>
													<div className="flex justify-between border-b-2 border-gray">
														<p className="text-xs md:text-base font-bold">PV System Size</p>
														<p>{Number(parent.final_system_Size).toLocaleString("en-US") + " "}kW</p>
													</div>
													<div className="flex justify-between border-b-2 border-gray">
														<p className="text-xs md:text-base font-bold">Monitoring Type</p>
														<p>{parent.monitoringType ? parent.monitoringType : "-"}</p>
													</div>
												</div>
											</div>

											<EnvironmentalImpact generatedToDate={parent.energyLifetime} />
										</div>
										<div className="w-full flex flex-col border border-black rounded-lg bg-black mt-4 md:mb-0">
											<p className="text-lg text-white p-2 font-bold text-center">Performance & Maintenance</p>
											<div className=" flex flex-col md:flex-row bg-white rounded-b-lg h-full w-full">
												<div className="flex flex-col items-center justify-between w-full gap-4 p-2 md:border-r border-b md:border-b-0 border-black">
													<p className="text-xs md:text-base font-bold">Tickets</p>
													<p
														onClick={() => setShowTicketsModal(true)}
														className="cursor-pointer text-blue-500 hover:text-blue-700 underline">
														{allTickets ? Number(allTickets.length).toLocaleString("en-US") : 0}
													</p>
												</div>

												<div className="flex flex-col items-center justify-between w-full gap-4 p-2 md:border-r border-b md:border-b-0 border-black">
													<p className="text-xs md:text-base font-bold">Alerts</p>
													<AlertIconList site={parent} />
												</div>

												<div className="flex flex-col items-center justify-between w-full gap-4 p-2 md:border-r border-b md:border-b-0 border-black">
													<p className="text-xs md:text-base font-bold">System Turn On Date</p>
													<p>{parent.x_studio_system_turned_on_date_p ? formatDate(parent.x_studio_system_turned_on_date_p) : "-"}</p>
												</div>

												<div className="flex flex-col items-center justify-between w-full gap-4 p-2 md:border-r border-b md:border-b-0 border-black">
													<p className="text-xs md:text-base font-bold">PTO Approval Date</p>
													<p>{parent.x_studio_pto_approved_p ? formatDate(parent.x_studio_pto_approved_p) : "-"}</p>
												</div>

												<div className="flex flex-col items-center justify-between w-full gap-4 p-2 md:border-r border-b md:border-b-0 border-black">
													<p className="text-xs md:text-base font-bold">Panel Cleaning</p>
													<p>{panelCleaningDate ? formatDate(panelCleaningDate) : "-"}</p>
												</div>

												<div className="flex flex-col items-center justify-between w-full gap-4 p-2 border-black">
													<p className="text-xs md:text-base font-bold">System Check</p>
													<p>{systemCheckDate ? formatDate(systemCheckDate) : "-"}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="flex flex-col justify-center items-center mt-4 bg-white p-4 rounded-lg border border-black">
								<p className="text-2xl font-bold ">System Overview</p>
							</div>

							<div
								className={`p-3 mt-4 sm:hidden flex items-center justify-center w-auto cursor-pointer ${
									user.email === "ps@1stle.com"
										? "bg-[#FFAC1C] hover:bg-orange-500"
										: user.email === "ess@1stle.com"
										? "bg-lime-400 hover:bg-lime-500"
										: isCommercial
										? "bg-[#FFEA00] hover:bg-yellow-400"
										: "bg-blue-500 hover:bg-blue-600"
								}`}
								onClick={() => setShowRotateModal(true)}>
								<p className="font-bold cursor-pointer">View Graphs</p>
							</div>

							<div className="text-color-black w-full hidden sm:grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-4 mt-0 sm:mt-4">
								<MyGraph
									containerTitle="Trailing 13 Months"
									timeFilter={"trailing13"}
									startExpanded={true}
									dataList={[
										{
											name: "Measured kWh",
											label: "Measured",
											data: parent.measuredData,
											timeKey: "Date",
											dataKey: "kWh",
											graphConfig: {
												visible: true,
												graphType: "bar",
												fillColor: "#82ca9d",
												stackId: "Measured",
											},
										},
										...(user.email === "ps@1stle.com" || user.email === "ess@1stle.com" || user.email === "costless@1stle.com"
											? [
													{
														name: "UW Expected",
														label: "UW Expected",
														data: dailyExpectedData,
														timeKey: "Date",
														dataKey: "kWh",
														graphConfig: {
															visible: true,
															graphType: "bar",
															fillColor: "#8884d8",
														},
													},
											  ]
											: []),
										...(user.email !== "costless@1stle.com"
											? [
													{
														name: "As Built",
														label: "As Built",
														data: parent.asBuiltData,
														timeKey: "Date",
														dataKey: "kWh",
														graphConfig: {
															visible: false,
															graphType: "bar",
															fillColor: "#ffa07a",
														},
													},
											  ]
											: []),
										...(inverterData && inverterData.length > 0
											? [
													{
														name: "Inverters",
														label: "Inverters",
														data: inverterData,
														timeKey: "Date",
														dataKey: "kWh",
														graphConfig: {
															visible: false,
															graphType: "bar",
															fillColor: "#95e89b",
															stackId: "Measured",
															parentLabel: "Measured",
														},
													},
											  ]
											: []),
									]}
								/>
								<MyGraph
									containerTitle="Trailing 30 Days"
									timeFilter={"trailing30"}
									startExpanded={false}
									dataList={[
										{
											name: "Measured kWh",
											label: "Measured",
											data: parent.measuredData,
											timeKey: "Date",
											dataKey: "kWh",
											graphConfig: {
												visible: true,
												graphType: "bar",
												fillColor: "#82ca9d",
												stackId: "Measured",
											},
										},
										...(user.email === "ps@1stle.com" || user.email === "ess@1stle.com" || user.email === "costless@1stle.com"
											? [
													{
														name: "UW Expected",
														label: "UW Expected",
														data: dailyExpectedData,
														timeKey: "Date",
														dataKey: "kWh",
														graphConfig: {
															visible: true,
															graphType: "bar",
															fillColor: "#8884d8",
														},
													},
											  ]
											: []),
										...(user.email !== "costless@1stle.com"
											? [
													{
														name: "As Built",
														label: "As Built",
														data: parent.asBuiltData,
														timeKey: "Date",
														dataKey: "kWh",
														graphConfig: {
															visible: false,
															graphType: "bar",
															fillColor: "#ffa07a",
														},
													},
											  ]
											: []),
										...(inverterData && inverterData.length > 0
											? [
													{
														name: "Inverters",
														label: "Inverters",
														data: inverterData,
														timeKey: "Date",
														dataKey: "kWh",
														graphConfig: {
															visible: false,
															graphType: "bar",
															fillColor: "#95e89b",
															stackId: "Measured",
															parentLabel: "Measured",
														},
													},
											  ]
											: []),
									]}
								/>

								<MyGraph
									containerTitle="Daily"
									timeFilter={"weekly"}
									startExpanded={false}
									dataList={[
										{
											name: "Measured kWh",
											label: "Measured",
											data: parent.measuredData,
											timeKey: "Date",
											dataKey: "kWh",
											graphConfig: {
												visible: true,
												graphType: "bar",
												fillColor: "#82ca9d",
												stackId: "Measured",
											},
										},
										...(user.email === "ps@1stle.com" || user.email === "ess@1stle.com" || user.email === "costless@1stle.com"
											? [
													{
														name: "UW Expected",
														label: "UW Expected",
														data: dailyExpectedData,
														timeKey: "Date",
														dataKey: "kWh",
														graphConfig: {
															visible: true,
															graphType: "bar",
															fillColor: "#8884d8",
														},
													},
											  ]
											: []),
										...(user.email !== "costless@1stle.com"
											? [
													{
														name: "As Built",
														label: "As Built",
														data: parent.asBuiltData,
														timeKey: "Date",
														dataKey: "kWh",
														graphConfig: {
															visible: false,
															graphType: "bar",
															fillColor: "#ffa07a",
														},
													},
											  ]
											: []),
										...(inverterData && inverterData.length > 0
											? [
													{
														name: "Inverters",
														label: "Inverters",
														data: inverterData,
														timeKey: "Date",
														dataKey: "kWh",
														graphConfig: {
															visible: false,
															graphType: "bar",
															fillColor: "#95e89b",
															stackId: "Measured",
															parentLabel: "Measured",
														},
													},
											  ]
											: []),
									]}
								/>
							</div>
							{(inverters && inverters.length > 0) || (solarEdgeInverters && solarEdgeInverters.length > 0) ? (
								<InvertersGrid
									inverters={[
										...(inverters || []).map((inv) => ({ ...inv, type: "solis" })),
										...(solarEdgeInverters || []).map((inv) => ({ ...inv, type: "solarEdge" })),
									]}
									title=" Inverter Overview"
								/>
							) : null}
						</>
					) : (
						<>
							<div className="mt-4 flex flex-col items-center">
								<p className="font-bold text-2xl mb-2">This site's data is currently being migrated.</p>
								<p className="font-bold text-xl mb-4">
									Please check over on the{" "}
									<Link to="/monitoring" className="text-blue-600 hover:text-blue-700 underline">
										Monitoring
									</Link>{" "}
									tab to see the sites with data available.
								</p>
							</div>
						</>
					)}
					<div>
						<div className={`grid grid-cols-1 mt-4 gap-4`}>
							{fetchingData ? null : children && children.length > 1 ? (
								<>
									<div className="flex flex-col justify-center items-center mt-4 bg-white p-4 rounded-lg border border-black">
										<p className="text-2xl font-bold ">Meters</p>
									</div>

									<div
										className={`p-3 sm:hidden flex items-center justify-center w-auto cursor-pointer ${
											user.email === "ps@1stle.com"
												? "bg-[#FFAC1C] hover:bg-orange-500"
												: user.email === "ess@1stle.com"
												? "bg-lime-400 hover:bg-lime-500"
												: isCommercial
												? "bg-[#FFEA00] hover:bg-yellow-400"
												: "bg-blue-500 hover:bg-blue-600"
										}`}
										onClick={() => setShowRotateModal(true)}>
										<p className="font-bold cursor-pointer">View Graphs</p>
									</div>

									{children.map((mapData, index) => {
										return (
											//className={`${index % 2 === 0 ? "bg-white" : "bg-gray-300"}`}
											<div key={index}>
												<div className="flex flex-col justify-center items-center text-lg md:text-2xl font-bold mb-4  ">
													<p>Meter {extractLastLetterIfPatternMet(mapData.name, index)}</p>
													<p className="font-normal text-sm">{`${mapData.meterKw.toFixed(1)} kW`}</p>
												</div>

												<div className="text-color-black w-full hidden sm:grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-4 mb-4">
													<MyGraph
														containerTitle="Trailing 13 Months"
														timeFilter={"trailing13"}
														startExpanded={true}
														solarEdgePercentage={mapData.meter_percentage}
														dataList={[
															{
																name: "Measured kWh",
																label: "Measured",
																data: mapData.measuredData,
																timeKey: "Date",
																dataKey: "kWh",
																graphConfig: {
																	visible: true,
																	graphType: "bar",
																	fillColor: "#82ca9d",
																},
															},
															...(user.email === "ps@1stle.com" || user.email === "ess@1stle.com" || user.email === "costless@1stle.com"
																? [
																		{
																			name: "UW Expected",
																			label: "UW Expected",
																			data: mapData.expectedData,
																			timeKey: "Date",
																			dataKey: "kWh",
																			graphConfig: {
																				visible: true,
																				graphType: "bar",
																				fillColor: "#8884d8",
																			},
																		},
																  ]
																: []),
															...(user.email !== "costless@1stle.com"
																? [
																		{
																			name: "As Built",
																			label: "As Built",
																			data: parent.asBuiltData,
																			timeKey: "Date",
																			dataKey: "kWh",
																			graphConfig: {
																				visible: false,
																				graphType: "bar",
																				fillColor: "#ffa07a",
																			},
																		},
																  ]
																: []),
														]}
													/>
													<MyGraph
														containerTitle="Trailing 30 Days"
														timeFilter={"trailing30"}
														startExpanded={false}
														solarEdgePercentage={mapData.meter_percentage}
														dataList={[
															{
																name: "Measured kWh",
																label: "Measured",
																data: mapData.measuredData,
																timeKey: "Date",
																dataKey: "kWh",
																graphConfig: {
																	visible: true,
																	graphType: "bar",
																	fillColor: "#82ca9d",
																},
															},
															...(user.email === "ps@1stle.com" || user.email === "ess@1stle.com" || user.email === "costless@1stle.com"
																? [
																		{
																			name: "UW Expected",
																			label: "UW Expected",
																			data: mapData.expectedData,
																			timeKey: "Date",
																			dataKey: "kWh",
																			graphConfig: {
																				visible: true,
																				graphType: "bar",
																				fillColor: "#8884d8",
																			},
																		},
																  ]
																: []),
															...(user.email !== "costless@1stle.com"
																? [
																		{
																			name: "As Built",
																			label: "As Built",
																			data: parent.asBuiltData,
																			timeKey: "Date",
																			dataKey: "kWh",
																			graphConfig: {
																				visible: false,
																				graphType: "bar",
																				fillColor: "#ffa07a",
																			},
																		},
																  ]
																: []),
														]}
													/>

													<MyGraph
														containerTitle="Daily"
														timeFilter={"weekly"}
														startExpanded={false}
														solarEdgePercentage={mapData.meter_percentage}
														dataList={[
															{
																name: "Measured kWh",
																label: "Measured",
																data: mapData.measuredData,
																timeKey: "Date",
																dataKey: "kWh",
																graphConfig: {
																	visible: true,
																	graphType: "bar",
																	fillColor: "#82ca9d",
																},
															},
															...(user.email === "ps@1stle.com" || user.email === "ess@1stle.com" || user.email === "costless@1stle.com"
																? [
																		{
																			name: "UW Expected",
																			label: "UW Expected",
																			data: mapData.expectedData,
																			timeKey: "Date",
																			dataKey: "kWh",
																			graphConfig: {
																				visible: true,
																				graphType: "bar",
																				fillColor: "#8884d8",
																			},
																		},
																  ]
																: []),
															...(user.email !== "costless@1stle.com"
																? [
																		{
																			name: "As Built",
																			label: "As Built",
																			data: parent.asBuiltData,
																			timeKey: "Date",
																			dataKey: "kWh",
																			graphConfig: {
																				visible: false,
																				graphType: "bar",
																				fillColor: "#ffa07a",
																			},
																		},		
																  ]
																: []),
														]}
													/>
												</div>
											</div>
										);
									})}
								</>
							) : null}
						</div>
					</div>

					{isCommercial ? (
						fetchingData ? null : parent ? (
							!emptyExpectedTable ? (
								<>
									<div className="flex justify-center items-center mb-4 bg-white p-4 rounded-lg border border-black">
										<p className="text-2xl font-bold text-center flex-grow">Expectations Table</p>
									</div>
									<ExpectationsCalculator expectedTableData={expectedTableData} />
								</>
							) : null
						) : null
					) : null}

					{/* 
					{fetchingData ? null : (
						<div className="mt-4 flex justify-center">
							<a
								className="text-blue-400 hover:text-blue-600 cursor-pointer underline"
								href={process.env.REACT_APP_TROUBLESHOOTING_PDF_URL}
								download>
								Experiencing difficulties? Please consult our Troubleshooting FAQ for assistance
							</a>
						</div>
					)} */}

					{showRotateModal ? <GraphModal setShowRotateModal={setShowRotateModal} /> : null}

					<p className="text-center text-xs text-underline">Meter data refreshes daily at 2 AM PT</p>
					<p className="text-center text-xs text-underline">Inverter data refreshes daily at 11 AM and 3PM PT</p>
				</div>
			</div>
		</>
	);
}
