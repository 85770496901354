import { Fragment } from "react";
import InverterRow from "./InverterRow";

const InvertersGrid = ({ inverters, title = "Inverter Overview" }) => {
	const renderRow = (inverter) => {
		if (inverter.type === "solis") {
			return (
				<Fragment key={inverter.inverter_sn}>
					<InverterRow inverter={inverter} />
				</Fragment>
			);
		} else {
			return (
				<Fragment key={inverter.site_id}>
					<p>{inverter.site_id}</p>
					<p>Solar Edge</p>
					<p>
						{inverter.energy ? inverter.energy.toFixed(2) : "-"}
						<span className="text-xs"> kWh</span>
					</p>
					<p></p>
				</Fragment>
			);
		}
	};

	return (
		<>
			<div className="flex flex-col justify-center items-center mt-4 bg-white p-4 rounded-lg border border-black">
				<p className="text-2xl font-bold">{title}</p>
			</div>
			<div className="mt-4 rounded-lg border border-black bg-black">
				<p className="bg-black rounded-t-lg p-4 text-white text-center text-lg font-bold">Latest Information</p>
				<div className="grid grid-cols-4 gap-3 justify-center items-center rounded-b-lg bg-white p-4">
					<p className="font-bold">ID</p>
					<p className="font-bold">Type</p>
					<p className="font-bold">Yesterday Yield</p>
					<p className="font-bold"></p>
					{inverters.map((inv) => renderRow(inv))}
				</div>
			</div>
		</>
	);
};

export default InvertersGrid;
